export const REG = {
  kana: /^[ぁ-んー～！？、。]*$/,
  bankTransferAccount: {
    // 事業略語 '()' が不要な略語のリスト
    abbreviation:
      /^.*(ﾚﾝ|ｷﾖｳｻｲ|ｷﾖｳｸﾐ|ｾｲﾒｲ|ｶｲｼﾞﾖｳ|ｶｻｲ|ｹﾝﾎﾟ|ｺｸﾎ|ｺｸﾎﾚﾝ|ｼﾔﾎ|ｺｳﾈﾝ|ｼﾞﾕｳｸﾐ|ﾛｳｸﾐ|ｾｲｷﾖｳ|ｼﾖｸﾊﾝｷﾖｳ|ｺｸｷﾖｳﾚﾝ|ﾉｳｷﾖｳﾚﾝ|ｹｲｻﾞｲﾚﾝ|ｷﾖｳｻｲﾚﾝ|ｷﾞﾖｷﾖｳ|ｷﾞﾖﾚﾝ|ｼﾖｸｱﾝ|ｼﾔｷﾖｳ|ﾄｸﾖｳ|ﾕｳｸﾐ).*$/,
    // カッコがどこかに含まれている必要がある
    parentheses: /^.*[()].*$/,
    // 使用できる文字(半角カタカナ英数字、カッコ「()」、マイナス記号「-」、バックスラッシュ「\」、カギカッコ｢｣、半角スペース)
    availableCharacters: /^[ｦｱ-ﾟｰ\-0-9A-Z｢｣()\\ ]+$/,
  },
  postalCode: /^\d{7}$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  tel: /^0\d{9,13}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
} as const
